import { createSlice } from "@reduxjs/toolkit";
import { getJobberOAuthCallback, getGetJobberOAuthLoginUrl } from "./getJobberOAuthActions";


const initialState = {

};

const getJobberOAuthSlice = createSlice({
  name: "getJobberOAuth",
  initialState,
  reducers: {
  },
  extraReducers: {
    // get token using code
    [getGetJobberOAuthLoginUrl.pending]: (state) => {
      state.loading = true;
      state.objGetGetJobberOAuthLoginUrlData = null;
    },
    [getGetJobberOAuthLoginUrl.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objGetGetJobberOAuthLoginUrlData = payload;
    },
    [getGetJobberOAuthLoginUrl.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objGetGetJobberOAuthLoginUrlData = payload;
    },
    // get token using code
    [getJobberOAuthCallback.pending]: (state) => {
      state.loading = true;
      state.objgetJobberOAuthCallbackData = null;
    },
    [getJobberOAuthCallback.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objgetJobberOAuthCallbackData = payload;
    },
    [getJobberOAuthCallback.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objgetJobberOAuthCallbackData = payload;
    },



  },
});

export default getJobberOAuthSlice.reducer;
