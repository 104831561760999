import { configureStore } from "@reduxjs/toolkit";
// import userReducer from "../user/userSlice";

import rootReducer from "./rootReducer";
function saveToLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    console.log(e);
  }
}

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   // Ignore these action types
      //   ignoredActions: ['your/action/type'],
      //   // Ignore these field paths in all actions
      //   ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
      //   // Ignore these paths in the state
      //   ignoredPaths: ['items.dates'],
      // },
      serializableCheck: false,
    }),
  reducer: rootReducer,
  // reducer: {
  //   user: userReducer,
  // },
});
store.subscribe(() => saveToLocalStorage(store.getState()));
export default store;
