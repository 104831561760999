import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL, GETJOBBER_OAUTH_SERVER } from "../../components/utils/constants/misc";


export const getGetJobberOAuthLoginUrl = createAsyncThunk("getJobberOAuth/get-oauth-login-url", async (values, thunkApi) => {
  try {
    const res = await axios.post(`${BACKEND_API_URL}${GETJOBBER_OAUTH_SERVER}/get-oauth-login-url`, values);
    return res;
  } catch (error) {
    // return custom error message from API if any
    return thunkApi.rejectWithValue(error);
  }
});

export const getJobberOAuthCallback = createAsyncThunk("getJobberOAuth/callback", async (values, thunkApi) => {
  try {
    const res = await axios.post(`${BACKEND_API_URL}${GETJOBBER_OAUTH_SERVER}/callback`, values);
    return res;
  } catch (error) {
    // return custom error message from API if any
    return thunkApi.rejectWithValue(error);
  }
});

