import { combineReducers } from "redux";
import userReducers from "../user/";
import googleReducers from "../google/";
import goHighLevelReducers from "../goHighLevel/";
import getJobberReducers from "../getJobber/";
import eventConnectionsReducers from "../eventConnections/";
const rootReducer = combineReducers({
  auth: userReducers,
  google: googleReducers,
  goHighLevel: goHighLevelReducers,
  getJobber: getJobberReducers,
  eventConnections: eventConnectionsReducers,
});

export default rootReducer;
