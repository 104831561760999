import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL, GOOGLE_SERVER } from "../../components/utils/constants/misc";

export const googleGetTokenUsingCode = createAsyncThunk("google/get-token-using-code", async (values, thunkApi) => {
  try {
    const res = await axios.post(`${BACKEND_API_URL}${GOOGLE_SERVER}/get-token-using-code`, values);
    return res;
  } catch (error) {
    // return custom error message from API if any
    return thunkApi.rejectWithValue(error);
  }
});

