import React, { Fragment, useState } from "react";
import axios from "axios";
import { Row, Col, Label, FormGroup, Button, Input } from "reactstrap";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { auth, userLogin } from "../../../../features/user/userActions";
import { showMsg } from "../../../../components/utils/general";
import LoadingSpinner from "../../../../components/utils/Helpers/Loading/LoadingSpinner";
// import ReCAPTCHA from "react-google-recaptcha";
// import { useGoogleLogin } from '@react-oauth/google';
// import { googleGetTokenUsingCode } from "../../../../features/google/googleActions";

const formLoginJWTSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Email is Required"),
  password: Yup.string()
    .min(8, "Password is too short!(min 8 characters are required)")
    .required("Password is required"),
})
const LoginForm = (props) => {
  const { objInitialValues, formRef } = props;
  const recaptchaRef = React.useRef();
  const [gCaptachaToken, setGCaptachaToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmitForm = async (values) => {
    
    console.log("values in onSubmitForm");
    console.log("values in onSubmitForm");
    console.log("values in onSubmitForm");
    console.log("values in onSubmitForm");
    console.log(values);
    const objPostData = {
      ...values,
    };
    setIsLoading(true);
    dispatch(userLogin(objPostData))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        console.log("originalPromiseResult in userLogin");
        console.log("originalPromiseResult in userLogin");
        console.log("originalPromiseResult in userLogin");
        console.log(originalPromiseResult);
        const response = originalPromiseResult;

        console.log("response.data in userLogin");
        console.log("response.data in userLogin");
        console.log("response.data in userLogin");
        console.log(response.data);
        if (response.data.error === false) {
          showMsg(
            "success",
            "You have been successfully logged in."
          );
          axios.defaults.headers.common['Authorization'] = response.data.token;
          localStorage.setItem('token', response.data.token);
          dispatch(auth())
              .then(unwrapResult)
              .then((originalPromiseResultAuth) => {
                setIsLoading(false);
                console.log("originalPromiseResultAuth in auth");
                console.log("originalPromiseResultAuth in auth");
                console.log("originalPromiseResultAuth in auth");
                console.log(originalPromiseResultAuth);
                let redirectUrl = '/dashboard';
          
                console.log('redirectUrl in loginUser')
                console.log('redirectUrl in loginUser')
                console.log(redirectUrl)
                navigate(redirectUrl);
              })
              .catch((rejectedValueOrSerializedErrorAuth) => {
                setIsLoading(false);
                showMsg("error", "Please correct the errors.");
                // console.log("rejectedValueOrSerializedError.response.data.errors");
                // console.log("rejectedValueOrSerializedError.response.data.errors");
                // console.log("rejectedValueOrSerializedError.response.data.errors");
                // console.log(rejectedValueOrSerializedError.response.data.errors);
                // handle result here
              });
          
          
        } else {
          showMsg("error", response.data.msg);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Ops something went wrong, Please try again");
        console.log("rejectedValueOrSerializedError");
        console.log("rejectedValueOrSerializedError");
        console.log("rejectedValueOrSerializedError");
        console.log(rejectedValueOrSerializedError);
      });
  };
  
  
  return (
    <Formik
      innerRef={formRef}
      initialValues={objInitialValues}
      validationSchema={formLoginJWTSchema}
      onSubmit={onSubmitForm}
    >
      {({ errors, touched }) => (
        <Form>
          <h4 className="text-theme fw-600 fs-32 mb-30">Login</h4>
          <Row>
            <Col>
              <FormGroup>
                <Label className="text-theme">Email Address</Label>
                {/* <Input type="email" name="email" id="email" placeholder="Email" /> */}
                <Field
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter Your Email"
                  className={`form-control ${errors.email &&
                      touched.email &&
                      "is-invalid"}`}
                />
                
                {errors.email && touched.email ? (
                  <div className="invalid-feedback">{errors.email}</div>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label className="text-theme" for="password">
                  Password
                </Label>
                <Field
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter Your Password"
                  className={`form-control ${errors.password &&
                      touched.password &&
                      "is-invalid"}`}
                />
                
                {errors.password && touched.password ? (
                  <div className="invalid-feedback">{errors.password}</div>
                ) : null}
                {/* <input type="password" id="password" name="password" className="form-control"  placeholder="Enter Your Password" required /> */}
                {/* <Input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                /> */}
              </FormGroup>

              {/* <Col className='col-lg-12 form-condition'>
                <div className='agree-label'>
                  <input type='checkbox' id='chb1' />
                  <label htmlFor='chb1'>
                    Remember Me
                    <Link to='/forgot-password'>
                      <a className='forget'>
                        Forgot My Password?
                      </a>
                    </Link>
                  </label>
                </div>
              </Col> */}
              {/* <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_CLIENT_KEY}
                onChange={onChangeCaptachaToken}
              /> */}
              <FormGroup className="mt-30">
                {
                  isLoading === false ?
                    <Button className="btn-second btn-submit full-width btn btn-success btnLogin">
                      Login
                    </Button>
                  : (
                    <LoadingSpinner subClass="text-center" color="danger" size="lg" />
                  )
                }
              </FormGroup>

              <Col>
                <div className="sub-title">
                  <span>Or</span>
                </div>
              </Col>

              <div className="login-with-account">
                <ul>
                  {/* <li>
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="ri-facebook-line"></i>Login with Facebook
                </a>
              </li> */}
                  {/* <li>
                    <a
                      href="#"
                      rel="noreferrer"
                      // onClick={() => loginGoogle()}
                    >
                      <i className="bx bxl-google"></i>Login with Google
                    </a>
                  </li> */}
                  <li>
                    {/* <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="bx bxl-google"></i>Create new
                </a> */}
                    <li>
                      <Link to='/register' className="btnWhite">
                        Create New Account
                      </Link>
                    </li>
                  </li>
                </ul>
              </div>


              {/* <FormGroup className="text-center text-theme fs-14">
            Login with
          </FormGroup>

          <FormGroup className="mt-30">
            <Button className="btn-second btn-submit full-width btn btn-success btnLogin">
              <i class="bx bxl-google"></i> Google
            </Button>
          </FormGroup> */}

              {/* <FormGroup className="text-center text-theme fs-14">
            <a href="/register" rel="noopener">
              <span className="text-light-green"> Sign in here</span>
            </a>{" "}
            If you already have an account.
          </FormGroup> */}
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
