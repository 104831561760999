import { createSlice } from "@reduxjs/toolkit";
import { googleGetTokenUsingCode } from "./googleActions";


const initialState = {
  // loading: false,
  // userInfo: null,
  // userToken,
  // error: null,
  // success: false,
};

const googleSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
  },
  extraReducers: {
    // get token using code
    [googleGetTokenUsingCode.pending]: (state) => {
      state.loading = true;
      state.objGoogleGetTokenUsingCodeData = null;
    },
    [googleGetTokenUsingCode.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objGoogleGetTokenUsingCodeData = payload;
    },
    [googleGetTokenUsingCode.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objGoogleGetTokenUsingCodeData = payload;
    },

    
    
  },
});

export default googleSlice.reducer;
