import React from 'react';
// import { BrowserRouter, Switch } from 'react-router-dom'
import {
  BrowserRouter,
  Routes,
  Route,
  // Outlet, NavLink
} from "react-router-dom";
// import { BrowserRouter, Switch, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import "animate.css"
// import './assets/css/animate.min.css';
import './assets/css/meanmenu.min.css';
import './assets/css/boxicons.min.css';
import './assets/css/flaticon.css';
import './assets/css/nice-select.min.css';
import './assets/scss/style.scss';
import './assets/scss/responsive.scss';
// import './assets/css/style.css';
// import './assets/css/responsive.css';
// import Layout from './Layout/Layout';
// import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Register from './pages/Auth/Register/Register';
import Login from './pages/Auth/Login/Login';

import { IndiceProvider } from './contexts';
import NoAuthLayout from './NoAuthLayout/NoAuthLayout';
import AuthHoc from './hoc/Auth/AuthHoc';
// import ForgotPassword from './pages/Auth/ForgotPassword/ForgotPassword';
// import ResetPassword from './pages/Auth/ResetPassword/ResetPassword';
import Dashboard from './pages/Dashboard/Dashboard';
import GoHighLevelOAuthCallBack from './pages/GoHighLevelOAuthCallBack/GoHighLevelOAuthCallBack';
import GetJobberOAuthCallBack from './pages/GetJobberOAuthCallBack/GetJobberOAuthCallBack';
import RedirectToDashboardIfLoggedInCheck from './hoc/Auth/RedirectToDashboardIfLoggedInCheck';


function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
        <IndiceProvider>
          <Routes>
            <Route element={<NoAuthLayout />}>
              <Route index path="/" element={
                  <RedirectToDashboardIfLoggedInCheck 
                    checkAuth={true} 
                    Component={Login}
                  />
                }
              />
              <Route index path="/register" element={
                  <RedirectToDashboardIfLoggedInCheck 
                    checkAuth={true} 
                    Component={Register}
                  />
                }
              />
              <Route index path="/login" element={
                  <RedirectToDashboardIfLoggedInCheck 
                    checkAuth={true} 
                    Component={Login}
                  />
                }
              />
              {/* <Route index path="/forgot-password" element={
                  <RedirectToDashboardIfLoggedInCheck 
                    checkAuth={true} 
                    Component={ForgotPassword}
                  />
                } 
              />   */}
              
              <Route index path="/dashboard" element={
                  <AuthHoc 
                    checkAuth={true} 
                    isRedirect={true} 
                    Component={Dashboard}
                    page_name={"dashboard"}
                  />
                } 
              />
              <Route index path="/go-high-level-oauth-callback" element={
                  <AuthHoc 
                    checkAuth={true} 
                    isRedirect={true} 
                    Component={GoHighLevelOAuthCallBack}
                    page_name={"gohighleveloauthcallback"}
                  />
                } 
              />
              <Route index path="/getjobber-oauth-callback" element={
                  <AuthHoc 
                    checkAuth={true} 
                    isRedirect={true} 
                    Component={GetJobberOAuthCallBack}
                    page_name={"getjobberoauthcallback"}
                  />
                } 
              />


                
              {/* <Route index path="/" element={
                  <AuthHoc 
                    checkAuth={true} 
                    isRedirect={true} 
                    Component={Dashboard}
                    page_name={"dashboard"}
                  />
                } 
              />   */}
            </Route>
          </Routes>
        </IndiceProvider>
    </BrowserRouter>
  );
}

export default App;
