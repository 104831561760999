import React, { useEffect } from 'react';
import {
    Outlet
} from "react-router-dom";
// import './App.css';
// import NavbarTwo from './NavbarTwo';
import NoAuthLayoutFooter from './NoAuthLayoutFooter';
import '../assets/scss/noAuth/noAuthStyle.scss';
// import Copyright from './Copyright';
const NoAuthLayout = () => {
    useEffect(() => {
        document.body.classList.add('noAuthPageLayout');
      
        return () =>{
          document.body.classList.remove('noAuthPageLayout');
        };
    }, []);
    return (
        <>
            {/* <NavbarTwo /> */}
                <Outlet />
            <NoAuthLayoutFooter />
            {/* <Footer/>
            <Copyright/> */}
        </>
    );
};

export default NoAuthLayout;