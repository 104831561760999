import React, { Fragment, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
// import { Row, Col, Form, Label, FormGroup, Button, Input } from "reactstrap";
import { Row, Col, Label, FormGroup, Button, Input } from "reactstrap";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

import { auth, registerUser } from "../../../../features/user/userActions";
import { showMsg } from "../../../../components/utils/general";
import LoadingSpinner from "../../../../components/utils/Helpers/Loading/LoadingSpinner";
// import ReCAPTCHA from "react-google-recaptcha";
// import { useGoogleLogin } from '@react-oauth/google';
import { googleGetTokenUsingCode } from "../../../../features/google/googleActions";

const formRegisterJWTSchema = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is Required"),
  password: Yup.string()
    .min(8, "Password is too short!(min 8 characters are required)")
    .required("Password is required"),
})
const LoginForm = (props) => {
  const { formRef, objInitialValues } = props;
  
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // const history = useHistory();
  const dispatch = useDispatch();
  const onSubmitForm = async (values) => {
    console.log("values in onSubmitForm");
    console.log("values in onSubmitForm");
    console.log(values);
    const objPostData = {
      ...values,
    };
    setIsLoading(true);
    dispatch(registerUser(objPostData))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        console.log("response.status in registerUser");
        console.log(response.status);
        if (response.data.error === false) {
          showMsg(
            "success",
            "You have been registered successfully, Please login now."
          );
          let redirectUrl = '/login';
          
          console.log('redirectUrl in loginUser')
          console.log('redirectUrl in loginUser')
          console.log(redirectUrl)
          navigate(redirectUrl);
        } else {
          showMsg("error", response.data.msg);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        // showMsg("error", "Please correct the errors.");
        console.log("rejectedValueOrSerializedError");
        console.log("rejectedValueOrSerializedError");
        console.log("rejectedValueOrSerializedError");
        console.log(rejectedValueOrSerializedError);
        showMsg("error", "Ops something went wrong, Please try again");
        // handle result here
      });
  };
  

  
  return (
    <Formik
      initialValues={objInitialValues}
      validationSchema={formRegisterJWTSchema}
      onSubmit={onSubmitForm}
    >
      {({ errors, touched }) => (
        <Form>
          <h4 className="text-theme fw-600 fs-32 mb-30">Sign up</h4>
          <Row>
            <Col>
              <FormGroup>
                <Label className="text-theme">Name</Label>
                <Field
                  name="name"
                  id="name"
                  placeholder="Enter Your Name"
                  className={`form-control ${errors.name &&
                    touched.name &&
                    "is-invalid"}`}
                />
                {
                  errors.name && touched.name ? (
                    <div className="invalid-feedback">{errors.name}</div>
                  ) : null
                }
              </FormGroup>
              <FormGroup>
                <Label className="text-theme">Email Address</Label>
                {/* <Input type="email" name="email" id="email" placeholder="Email" /> */}
                <Field
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter Your Email"
                  className={`form-control ${errors.email &&
                    touched.email &&
                    "is-invalid"}`}
                />

                {errors.email && touched.email ? (
                  <div className="invalid-feedback">{errors.email}</div>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label className="text-theme" for="password">
                  Password
                </Label>
                
                <Field
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Enter Your Password"
                  className={`form-control ${errors.password &&
                    touched.password &&
                    "is-invalid"}`}
                />
                {errors.password && touched.password ? (
                  <div className="invalid-feedback">{errors.password}</div>
                ) : null}
              </FormGroup>
              <FormGroup className="mt-30">
                {
                  isLoading === false ?
                    <Button className="btn-second btn-submit full-width btn btn-success btnLogin">
                      Register
                    </Button>
                  : (
                    <LoadingSpinner subClass="text-center" color="danger" size="lg" />
                  )
                }
              </FormGroup>

              

              <FormGroup className="text-center text-theme fs-14">
                If you already have a account?
                <Link to="/login"><span className="text-light-green"> Click here to Login</span></Link>
                {/* <a href="/" rel="noopener">
              <span className="text-light-green"> Click here to Login</span>
            </a> */}
                {" "}

              </FormGroup>
              {/* <Col>
                <div className="sub-title">
                  <span>Or</span>
                </div>
              </Col> */}

              <div className="login-with-account">
                <ul>
                  {/* <li>
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i class="ri-facebook-line"></i>Login with Facebook
                </a>
              </li> */}
                  {/* <li>
                    <a
                      href="#"
                      rel="noreferrer"
                      // onClick={() => loginGoogle()}
                    >
                      <i className="bx bxl-google"></i>Login with Google
                    </a>
                  </li> */}
                </ul>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
