import React, { useRef, useEffect, useState } from 'react';
// import loginImgPng from '../../assets/images/auth/login-image.png';
import { Row, Col, Label, Form, FormGroup, Input, Button } from 'reactstrap';
import Select from "react-select"
import makeAnimated from "react-select/animated";
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from '../../../../components/utils/general';
import LoadingSpinner from '../../../../components/utils/Helpers/Loading/LoadingSpinner';
const animatedComponents = makeAnimated();
const SelectWorkFlowsForJobberIntegrationForm = (props) => {
    const { 
        isLoadingWorkflows, objWorkflows, formattedWorkflowsArr, objFormErrors, 
        setObjFormErrors,
        objSelectedNewClientGoHighLevelWorkFlow, setObjSelectedNewClientGoHighLevelWorkFlow,
        objSelectedQuoteSentGoHighLevelWorkFlow, setObjSelectedQuoteSentGoHighLevelWorkFlow,
        objSelectedQuoteApprovedGoHighLevelWorkFlow, setObjSelectedQuoteApprovedGoHighLevelWorkFlow,
        objSelectedJobScheduledGoHighLevelWorkFlow, setObjSelectedJobScheduledGoHighLevelWorkFlow,
        objSelectedJobCompletedGoHighLevelWorkFlow, setObjSelectedJobCompletedGoHighLevelWorkFlow,
        objSelectedInvoiceSentGoHighLevelWorkFlow, setObjSelectedInvoiceSentGoHighLevelWorkFlow,
        objSelectedInvoicePaidGoHighLevelWorkFlow, setObjSelectedInvoicePaidGoHighLevelWorkFlow,
        btnOnClickSaveEventConnections, isSavingEventConnections
    } = props;
    const handleOnChangeNewClientGoHighLevelWorkFlow = (selectedOptions) => {
        console.log('selectedOptions')
        console.log('selectedOptions')
        console.log(selectedOptions)
        setObjSelectedNewClientGoHighLevelWorkFlow(selectedOptions);
        
    };

    const handleOnChangeQuoteSentGoHighLevelWorkFlow = (selectedOptions) => {
        console.log('selectedOptions')
        console.log('selectedOptions')
        console.log(selectedOptions)
        setObjSelectedQuoteSentGoHighLevelWorkFlow(selectedOptions);
        
    };


    const handleOnChangeQuoteApprovedGoHighLevelWorkFlow = (selectedOptions) => {
        console.log('selectedOptions')
        console.log('selectedOptions')
        console.log(selectedOptions)
        setObjSelectedQuoteApprovedGoHighLevelWorkFlow(selectedOptions);
        
    };

    const handleOnChangeJobScheduledGoHighLevelWorkFlow = (selectedOptions) => {
        console.log('selectedOptions')
        console.log('selectedOptions')
        console.log(selectedOptions)
        setObjSelectedJobScheduledGoHighLevelWorkFlow(selectedOptions);
        
    };

    const handleOnChangeJobCompletedGoHighLevelWorkFlow = (selectedOptions) => {
        console.log('selectedOptions')
        console.log('selectedOptions')
        console.log(selectedOptions)
        setObjSelectedJobCompletedGoHighLevelWorkFlow(selectedOptions);
        
    };

    const handleOnChangeInvoiceSentGoHighLevelWorkFlow = (selectedOptions) => {
        console.log('selectedOptions')
        console.log('selectedOptions')
        console.log(selectedOptions)
        setObjSelectedInvoiceSentGoHighLevelWorkFlow(selectedOptions);
        
    };

    const handleOnChangeInvoicePaidGoHighLevelWorkFlow = (selectedOptions) => {
        console.log('selectedOptions')
        console.log('selectedOptions')
        console.log(selectedOptions)
        setObjSelectedInvoicePaidGoHighLevelWorkFlow(selectedOptions);
        
    };
    return (
        <>
            {
                formattedWorkflowsArr.length > 0  ?
                <Row className="mt-50 mb-30 text-center">
                    <Form id={"SourceAutomationForm"}>
                        <Row className='g-4'>
                            <Col md="6">
                                <FormGroup className={`${objFormErrors.objSelectedNewClientGoHighLevelWorkFlow && "text-danger"}`}>
                                    <h6 className="text-bold-100">Select New Client WorkFlow of Social Summit Media Solutions</h6>
                                    <Select
                                        components={animatedComponents}
                                        name="drpNewClientGoHighLevelWorkFlow"
                                        options={formattedWorkflowsArr}
                                        className="React"
                                        classNamePrefix="select"
                                        value={objSelectedNewClientGoHighLevelWorkFlow}
                                        onChange={handleOnChangeNewClientGoHighLevelWorkFlow}
                                    />
                                    {objFormErrors.objSelectedNewClientGoHighLevelWorkFlow ? (
                                        <div className="invalid-feedback text-error d-block">{objFormErrors.objSelectedNewClientGoHighLevelWorkFlow}</div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className={`${objFormErrors.objSelectedQuoteSentGoHighLevelWorkFlow && "text-danger"}`}>
                                    <h6 className="text-bold-100">Select Quote Sent WorkFlow of Social Summit Media Solutions</h6>
                                    <Select
                                        components={animatedComponents}
                                        name="drpQuoteSentGoHighLevelWorkFlow"
                                        options={formattedWorkflowsArr}
                                        className="React"
                                        classNamePrefix="select"
                                        value={objSelectedQuoteSentGoHighLevelWorkFlow}
                                        onChange={handleOnChangeQuoteSentGoHighLevelWorkFlow}
                                    />
                                    {objFormErrors.objSelectedQuoteSentGoHighLevelWorkFlow ? (
                                        <div className="invalid-feedback text-error d-block">{objFormErrors.objSelectedQuoteSentGoHighLevelWorkFlow}</div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                        </Row>


                        <Row className='g-4'>
                            <Col md="6">
                                <FormGroup className={`${objFormErrors.objSelectedQuoteApprovedGoHighLevelWorkFlow && "text-danger"}`}>
                                    <h6 className="text-bold-100">Select Quote Approved WorkFlow of Social Summit Media Solutions</h6>
                                    <Select
                                        components={animatedComponents}
                                        name="drpQuoteApprovedGoHighLevelWorkFlow"
                                        options={formattedWorkflowsArr}
                                        className="React"
                                        classNamePrefix="select"
                                        value={objSelectedQuoteApprovedGoHighLevelWorkFlow}
                                        onChange={handleOnChangeQuoteApprovedGoHighLevelWorkFlow}
                                    />
                                    {objFormErrors.objSelectedQuoteApprovedGoHighLevelWorkFlow ? (
                                        <div className="invalid-feedback text-error d-block">{objFormErrors.objSelectedQuoteApprovedGoHighLevelWorkFlow}</div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className={`${objFormErrors.objSelectedJobScheduledGoHighLevelWorkFlow && "text-danger"}`}>
                                    <h6 className="text-bold-100">Select Job Scheduled WorkFlow of Social Summit Media Solutions</h6>
                                    <Select
                                        components={animatedComponents}
                                        name="drpJobScheduledGoHighLevelWorkFlow"
                                        options={formattedWorkflowsArr}
                                        className="React"
                                        classNamePrefix="select"
                                        value={objSelectedJobScheduledGoHighLevelWorkFlow}
                                        onChange={handleOnChangeJobScheduledGoHighLevelWorkFlow}
                                    />
                                    {objFormErrors.objSelectedJobScheduledGoHighLevelWorkFlow ? (
                                        <div className="invalid-feedback text-error d-block">{objFormErrors.objSelectedJobScheduledGoHighLevelWorkFlow}</div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                        </Row>


                        <Row className='g-4'>
                            <Col md="6">
                                <FormGroup className={`${objFormErrors.objSelectedJobCompletedGoHighLevelWorkFlow && "text-danger"}`}>
                                    <h6 className="text-bold-100">Select Job Completed WorkFlow of Social Summit Media Solutions</h6>
                                    <Select
                                        components={animatedComponents}
                                        name="drpJobCompletedGoHighLevelWorkFlow"
                                        options={formattedWorkflowsArr}
                                        className="React"
                                        classNamePrefix="select"
                                        value={objSelectedJobCompletedGoHighLevelWorkFlow}
                                        onChange={handleOnChangeJobCompletedGoHighLevelWorkFlow}
                                    />
                                    {objFormErrors.objSelectedJobCompletedGoHighLevelWorkFlow ? (
                                        <div className="invalid-feedback text-error d-block">{objFormErrors.objSelectedJobCompletedGoHighLevelWorkFlow}</div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className={`${objFormErrors.objSelectedInvoiceSentGoHighLevelWorkFlow && "text-danger"}`}>
                                    <h6 className="text-bold-100">Select Invoice Sent WorkFlow of Social Summit Media Solutions</h6>
                                    <Select
                                        components={animatedComponents}
                                        name="drpInvoiceSentGoHighLevelWorkFlow"
                                        options={formattedWorkflowsArr}
                                        className="React"
                                        classNamePrefix="select"
                                        value={objSelectedInvoiceSentGoHighLevelWorkFlow}
                                        onChange={handleOnChangeInvoiceSentGoHighLevelWorkFlow}
                                    />
                                    {objFormErrors.objSelectedInvoiceSentGoHighLevelWorkFlow ? (
                                        <div className="invalid-feedback text-error d-block">{objFormErrors.objSelectedInvoiceSentGoHighLevelWorkFlow}</div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                        </Row>


                        <Row className='g-4'>
                            <Col md="6">
                                <FormGroup className={`${objFormErrors.objSelectedInvoicePaidGoHighLevelWorkFlow && "text-danger"}`}>
                                    <h6 className="text-bold-100">Select Invoice Paid WorkFlow of Social Summit Media Solutions</h6>
                                    <Select
                                        components={animatedComponents}
                                        name="drpInvoicePaidGoHighLevelWorkFlow"
                                        options={formattedWorkflowsArr}
                                        className="React"
                                        classNamePrefix="select"
                                        value={objSelectedInvoicePaidGoHighLevelWorkFlow}
                                        onChange={handleOnChangeInvoicePaidGoHighLevelWorkFlow}
                                    />
                                    {objFormErrors.objSelectedInvoicePaidGoHighLevelWorkFlow ? (
                                        <div className="invalid-feedback text-error d-block">{objFormErrors.objSelectedInvoicePaidGoHighLevelWorkFlow}</div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            
                        </Row>
                        <Row>
                            {
                                isSavingEventConnections === false ?
                                <Button
                                    type='button'
                                    icon='add'
                                    color='success'
                                    isOutline
                                    onClick={() => btnOnClickSaveEventConnections()}
                                >
                                    Save event connections 
                                </Button>
                                : <LoadingSpinner subClass="text-center" color="danger" size="lg" />
                            }

                        </Row>
                        
                    </Form>
                </Row>
                : 
                <h2>No workflows found in your account, If the problem exists then reconnect your GoHighLevel Account again.</h2>
            }

        </>
    );
};

export default SelectWorkFlowsForJobberIntegrationForm;
