import { createSlice } from "@reduxjs/toolkit";
import { registerUser, userLogin, auth } from "./userActions";


const initialState = {
  // loading: false,
  // userInfo: null,
  // userToken,
  // error: null,
  // success: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // signupUser: (state,action) => {
    //   localStorage.removeItem("userToken"); // delete token from storage
    //   state.loading = false;
    //   state.userInfo = null;
    //   state.userToken = null;
    //   state.error = null;
    // },
  },
  extraReducers: {
    // register user
    [registerUser.pending]: (state) => {
      state.loading = true;
      state.objRegisterFormSuccess = null;
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objRegisterFormSuccess = payload;
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objRegisterFormSuccess = payload;
    },
    // login
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.objLoginFormSuccess = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objLoginFormSuccess = payload;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objLoginFormSuccess = payload;
    },

    // auth
    [auth.pending]: (state) => {
      state.loading = true;
    },
    [auth.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objUser = payload;
    },
    [auth.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objUser = payload;
    },
    
  },
});

// export const { logout } = userSlice.actions;

export default userSlice.reducer;
