import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL, USER_SERVER } from "../../components/utils/constants/misc";

export const userLogin = createAsyncThunk("user/login", async (values, thunkApi) => {
  try {
    // configure header's Content-Type as JSON
    const res = await axios.post(`${BACKEND_API_URL}${USER_SERVER}/login`, values);
    // return res.data;
    // localStorage.setItem('userToken', data.userToken)
    return res;

    // store user's token in local storage
  } catch (error) {
    // return custom error message from API if any
    return thunkApi.rejectWithValue(error);
  }
});

export const registerUser = createAsyncThunk("user/register", async (values, thunkApi) => {
  try {
    const res = await axios.post(`${BACKEND_API_URL}${USER_SERVER}/register`, values);
    return res;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});




export const auth = createAsyncThunk("user/auth", async (values, thunkApi) => {
  try {
    
    const res = await axios.get(`${BACKEND_API_URL}${USER_SERVER}/auth`);
    // console.log("res.data in auth");
    // console.log("res.data in auth");
    // console.log("res.data in auth");
    // console.log(res.data);
    // return res.data;
    return res.data;
    // return Promise.resolve(res.data);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});

export const logout = createAsyncThunk("user/logout", async (values, thunkApi) => {
  try {
    
    const res = await axios.post(`${BACKEND_API_URL}${USER_SERVER}/logout-user`, values);
    // return res.data;
    return res.data;
    // return Promise.resolve(res.data);
  } catch (error) {
    return thunkApi.rejectWithValue(error);
    
  }
});