import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL, GOHIGHLEVEL_OAUTH_SERVER } from "../../components/utils/constants/misc";


export const getGoHighLevelOAuthLoginUrl = createAsyncThunk("goHighLevelOAuth/get-oauth-login-url", async (values, thunkApi) => {
  try {
    const res = await axios.post(`${BACKEND_API_URL}${GOHIGHLEVEL_OAUTH_SERVER}/get-oauth-login-url`, values);
    return res;
  } catch (error) {
    // return custom error message from API if any
    return thunkApi.rejectWithValue(error);
  }
});

export const goHighLevelOAuthCallback = createAsyncThunk("goHighLevelOAuth/callback", async (values, thunkApi) => {
  try {
    const res = await axios.post(`${BACKEND_API_URL}${GOHIGHLEVEL_OAUTH_SERVER}/callback`, values);
    return res;
  } catch (error) {
    // return custom error message from API if any
    return thunkApi.rejectWithValue(error);
  }
});

