import { toast } from "react-toastify"
export function showMsg(type, msg, autoClose = 8000) {
  if (type === 'success') {
    toast.success(msg, { autoClose });
  } else if (type === 'error') {
    toast.error(msg, { autoClose });
  } else if (type === 'info') {
    toast.info(msg, { autoClose });
  }
}
