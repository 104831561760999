import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL, GOHIGHLEVEL_OAUTH_SERVER } from "../../components/utils/constants/misc";


export const getGoHighLevelWokflows = createAsyncThunk("goHighLevelOAuth/get-workflows", async (values, thunkApi) => {
  try {
    const res = await axios.post(`${BACKEND_API_URL}${GOHIGHLEVEL_OAUTH_SERVER}/get-workflows`, values);
    return res;
  } catch (error) {
    // return custom error message from API if any
    return thunkApi.rejectWithValue(error);
  }
});



