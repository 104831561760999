import React, { useRef, useState } from 'react';
// import loginImgPng from '../../assets/images/auth/login-image.png';
import { Row, Col, Button } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from '../../components/utils/general';
import LoadingSpinner from '../../components/utils/Helpers/Loading/LoadingSpinner';
import { getGetJobberOAuthLoginUrl } from '../../features/getJobber/getJobberOAuthActions';
import { getGoHighLevelOAuthLoginUrl } from '../../features/goHighLevel/goHighLevelOAuthActions';
import { logout } from '../../features/user/userActions';
import WorkFlowsSelectionForm from './components/WorkFlowsSelectionForm/WorkFlowsSelectionForm';
const Dashboard = (props) => {
    const { objUser } = props;
    const [isGeneratingGoHighLevelOAuthLink, setIsGeneratingGoHighLevelOAuthLink] = useState(false);
    const [isGeneratingGetJobberOAuthLink, setIsGeneratingGetJobberOAuthLink] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    
    const btnOnClickConnectWithSocialSummitSolutions = () => {
        setIsGeneratingGoHighLevelOAuthLink(true);
        dispatch(getGoHighLevelOAuthLoginUrl())
            .then(unwrapResult)
            .then((originalPromiseResult) => {
                setIsGeneratingGoHighLevelOAuthLink(false);
                console.log("originalPromiseResult in getGoHighLevelOAuthLoginUrl");
                console.log(originalPromiseResult);
                const response = originalPromiseResult;
                console.log("response.data in getGoHighLevelOAuthLoginUrl");
                console.log(response.data);
                if (response.data.error === false) {
                    window.location.href = response.data.authorizationUri;
                } else {
                    showMsg('error', response.data.msg);
                }
            })
            .catch((rejectedValueOrSerializedError) => {
                setIsGeneratingGoHighLevelOAuthLink(false);
                showMsg('error', "Ops something went wrong please try again")
                console.log("rejectedValueOrSerializedError");
                console.log(rejectedValueOrSerializedError);
            });
    }
    const btnOnClickConnectWithJobber = () => {
        setIsGeneratingGetJobberOAuthLink(true);
        dispatch(getGetJobberOAuthLoginUrl())
            .then(unwrapResult)
            .then((originalPromiseResult) => {
                setIsGeneratingGetJobberOAuthLink(false);
                console.log("originalPromiseResult in getGetJobberOAuthLoginUrl");
                console.log(originalPromiseResult);
                const response = originalPromiseResult;
                console.log("response.data in getGetJobberOAuthLoginUrl");
                console.log(response.data);
                if (response.data.error === false) {
                    window.location.href = response.data.authorizationUri;
                } else {
                    showMsg('error', response.data.msg);
                }
            })
            .catch((rejectedValueOrSerializedError) => {
                setIsGeneratingGetJobberOAuthLink(false);
                showMsg('error', "Ops something went wrong please try again")
                console.log("rejectedValueOrSerializedError");
                console.log(rejectedValueOrSerializedError);
            });
    }

    const btnOnClickLogout = () => {
        const objPostData = {};
        setIsLoading(true);

        dispatch(logout(objPostData))
            .then(unwrapResult)
            .then((originalPromiseResult) => {
                // setIsLoading(false);
                console.log("originalPromiseResult in auto logout");
                console.log(originalPromiseResult);
                const response = originalPromiseResult;
                if (response.data.error === false) {
                    showMsg("success", "You have been successfully logged out.");
                    localStorage.removeItem("token");
                    let redirectUrl = "/login";
                    navigate(redirectUrl);
                } else {
                    console.log("error in auto logout");
                    console.log("error in auto logout");
                    //  showNotification(response.data.msg,'','danger')
                    showMsg("error", "Ops something went wrong, Please try again");
                }
            })
            .catch((rejectedValueOrSerializedError) => {
                setIsLoading(false);
                // showMsg("error", "Please correct the errors.");
                console.log("rejectedValueOrSerializedError auto logout");
                console.log("rejectedValueOrSerializedError auto logout");
                console.log("rejectedValueOrSerializedError auto logout");
                console.log(rejectedValueOrSerializedError);
                localStorage.removeItem("token");
                let redirectUrl = "/login";
                navigate(redirectUrl);

            });
    }
    console.log('objUser in Dashboard');
    console.log('objUser in Dashboard');
    console.log(objUser)
    return (
        <>
            <div className="inner-wrapper loginPage">
                <div className="container-fluid no-padding">
                    {/* In Bootstrap 5 no-gutters === g-0 so we can remove this no-gutters class but I will remove it latter*/}
                    <Row className="g-0 no-gutters overflow-auto">
                        {/* <Col lg="6" md="6" sm="12" className="hideOnMobile">
                            <div className="main-banner">
                            </div>
                        </Col> */}
                        <Col lg="12" md="12" sm="12" className="bg-login-page text-center">
                            <div className="section-2 user-page main-padding text-center">
                                <div className="login-sec">
                                    <div className="">
                                        {
                                            isGeneratingGoHighLevelOAuthLink === false ?
                                                <Button
                                                    className="btn-submit full-width btn btn-primary"
                                                    onClick={() => btnOnClickConnectWithSocialSummitSolutions()}
                                                >
                                                    Connect With Social Summit Solutions
                                                </Button>
                                                : (
                                                    <LoadingSpinner subClass="text-center" color="danger" size="lg" />
                                                )
                                        }
                                        {
                                            isGeneratingGetJobberOAuthLink === false ?
                                                <Button className="btn-submit full-width btn btn-success mt-20"
                                                    onClick={() => btnOnClickConnectWithJobber()}
                                                >
                                                    Connect With Jobber
                                                </Button>
                                                : (
                                                    <LoadingSpinner subClass="text-center" color="danger" size="lg" />
                                                )
                                        }
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <WorkFlowsSelectionForm />
                    {/* <Row className="mt-50 mb-30 text-center">
                        <Col lg={6}>
                            
                        </Col>
                        <Col lg={6}>
                            
                        </Col>
                    </Row> */}
                    <Row className="mt-50 mb-30 text-center">
                        <Col >
                            <div className='btnFooter'>
                                {
                                    isLoading === false ?
                                        <Button className="btnLogout"
                                            onClick={() => btnOnClickLogout()}
                                        >Logout</Button>
                                    :
                                        <LoadingSpinner subClass="text-center" color="success" size="lg" />
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
