import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL, EVENT_CONNECTIONS_SERVER } from "../../components/utils/constants/misc";


export const saveJobberGoHighLevelEventConnections = createAsyncThunk("eventConnections/save-jobber-gohighlevel-event-connections", async (values, thunkApi) => {
  try {
    const res = await axios.post(`${BACKEND_API_URL}${EVENT_CONNECTIONS_SERVER}/save-jobber-gohighlevel-event-connections`, values);
    return res;
  } catch (error) {
    // return custom error message from API if any
    return thunkApi.rejectWithValue(error);
  }
});

export const fetchJobberGoHighLevelEventConnection = createAsyncThunk("eventConnections/fetch-jobber-gohighlevel-event-connection", async (values, thunkApi) => {
    try {
      const res = await axios.get(`${BACKEND_API_URL}${EVENT_CONNECTIONS_SERVER}/fetch-jobber-gohighlevel-event-connection`, values);
      return res;
    } catch (error) {
      // return custom error message from API if any
      return thunkApi.rejectWithValue(error);
    }
  });

