import React, { useEffect, useRef, useState } from 'react';
// import loginImgPng from '../../assets/images/auth/login-image.png';
import { Row, Col, Button } from 'reactstrap';
import queryString from 'query-string';
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { useNavigate } from 'react-router-dom';
import { showMsg } from '../../components/utils/general';
import { getJobberOAuthCallback } from '../../features/getJobber/getJobberOAuthActions';
import LoadingSpinner from '../../components/utils/Helpers/Loading/LoadingSpinner';
const GetJobberOAuthCallBack = (props) => {
    const { objUser } = props;
    const [isLoading, setIsLoading] = useState(false);
	const urlParams = queryString.parse(window.location.search);
	const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
		if (objUser !== undefined && Object.keys(objUser).length > 0 && Object.keys(urlParams).length > 0) {
			if (urlParams.code !== undefined) {

				const objPostData = {
					code:urlParams.code,
				};
				setIsLoading(true);
				dispatch(getJobberOAuthCallback(objPostData))
					.then(unwrapResult)
					.then((originalPromiseResult) => {
						setIsLoading(false);
						console.log("originalPromiseResult in userLogin");
						console.log("originalPromiseResult in userLogin");
						console.log("originalPromiseResult in userLogin");
						console.log(originalPromiseResult);
						const response = originalPromiseResult;
						console.log("response.data in userLogin");
						console.log("response.data in userLogin");
						console.log("response.data in userLogin");
						console.log(response.data);
						if (response.data.error === false) {
							showMsg('success',response.data.msg)
							// dispatch(updateAuthUserData(response.data.objUser))
							// setObjUser({ isAuth: true, ...response.data.objUser })
							let redirectUrl = '/dashboard';
							navigate(redirectUrl);
						} else {
							showMsg('error',response.data.msg);
							let redirectUrl = '/';
							// navigate(redirectUrl);
						}
					})
					.catch((rejectedValueOrSerializedError) => {
						setIsLoading(false);
						showMsg("Ops something went wrong please try again",'danger')
						console.log("rejectedValueOrSerializedError");
						console.log(rejectedValueOrSerializedError);
					});
			}else{
				showMsg('error',"Invalid Url")
				let redirectUrl = '/';
				// alert('else')
				// navigate(redirectUrl);
			}
		}else{
			let isError = false
			if(Object.keys(urlParams).length === 0){
				isError = true;
			}
			if(Object.keys(urlParams).length > 0 && urlParams.code === undefined){
				isError = true;
			}

			if(isError){
				showMsg("Invalid Url", '', 'danger')
				let redirectUrl = '/';
				// alert('else isError')
				// navigate(redirectUrl);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objUser]);
    console.log('objUser in GetJobberOAuthCallBack');
    console.log('objUser in GetJobberOAuthCallBack');
    console.log('objUser in GetJobberOAuthCallBack');
    console.log(objUser)
    return (
        <>
            <div className="inner-wrapper loginPage">
                <div className="container-fluid no-padding">
                    {/* In Bootstrap 5 no-gutters === g-0 so we can remove this no-gutters class but I will remove it latter*/}
                    <Row className="g-0 no-gutters overflow-auto">
                        {/* <Col lg="6" md="6" sm="12" className="hideOnMobile">
                            <div className="main-banner">
                            </div>
                        </Col> */}
                        <Col lg="12" md="12" sm="12" className="bg-login-page text-center">
                            <div className="section-2 user-page main-padding text-center">
                                <div className="login-sec">
                                    <div className="">
                                        {
                                            isLoading === false ?
											<h1>
												We are verifying your Jobber Account,Please wait.
											</h1>
											: (
												<LoadingSpinner subClass="text-center" color="danger" size="lg" />
											)
                                        }
                                        {/* {
                                            isGeneratingGoHighLevelOAuthLink === false ?
                                                <Button
                                                    className="btn-submit full-width btn btn-primary"
                                                    onClick={() => btnOnClickConnectWithSocialSummitSolutions()}
                                                >
                                                    Connect With Social Summit Solutions
                                                </Button>
                                                : (
                                                    <LoadingSpinner subClass="text-center" color="danger" size="lg" />
                                                )
                                        }
                                        <Button className="btn-submit full-width btn btn-success mt-20"
                                            onClick={() => btnOnClickConnectWithJobber()}
                                        >
                                            Connect With Jobber
                                        </Button> */}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default GetJobberOAuthCallBack;
