import { createSlice } from "@reduxjs/toolkit";
import { goHighLevelOAuthCallback, getGoHighLevelOAuthLoginUrl } from "./goHighLevelOAuthActions";


const initialState = {
  
};

const goHighLevelOAuthSlice = createSlice({
  name: "goHighLevelOAuth",
  initialState,
  reducers: {
  },
  extraReducers: {
    // get token using code
    [getGoHighLevelOAuthLoginUrl.pending]: (state) => {
      state.loading = true;
      state.objGetGoHighLevelOAuthLoginUrlData = null;
    },
    [getGoHighLevelOAuthLoginUrl.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objGetGoHighLevelOAuthLoginUrlData = payload;
    },
    [getGoHighLevelOAuthLoginUrl.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objGetGoHighLevelOAuthLoginUrlData = payload;
    },
    // get token using code
    [goHighLevelOAuthCallback.pending]: (state) => {
      state.loading = true;
      state.objGoHighLevelOAuthCallbackData = null;
    },
    [goHighLevelOAuthCallback.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objGoHighLevelOAuthCallbackData = payload;
    },
    [goHighLevelOAuthCallback.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objGoHighLevelOAuthCallbackData = payload;
    },

    
    
  },
});

export default goHighLevelOAuthSlice.reducer;
