import React, { useRef, useEffect, useState } from 'react';
// import loginImgPng from '../../assets/images/auth/login-image.png';
import { Row, Col, Button } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import queryString from 'query-string';
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from '../../../../components/utils/general';
import LoadingSpinner from '../../../../components/utils/Helpers/Loading/LoadingSpinner';
import { getGoHighLevelWokflows } from '../../../../features/goHighLevel/goHighLevelWorkFlowsActions';
import SelectWorkFlowsForJobberIntegrationForm from './SelectWorkFlowsForJobberIntegrationForm';
import { saveJobberGoHighLevelEventConnections, fetchJobberGoHighLevelEventConnection } from '../../../../features/eventConnections/goHighLevelAndJobberEventConnectionsActions';

const WorkFlowsSelectionForm = (props) => {
    const { objUser } = props;
    const [isLoadingWorkflows, setIsLoadingWorkflows] = useState(false);
    const [isWorkflowsLoaded, setIsWorkflowsLoaded] = useState(false);
    const [isLoadingEeventConnections, setIsLoadingEeventConnections] = useState(false);
    const [isSavingEventConnections, setIsSavingEventConnections] = useState(false);
    const [objUserEventConnectionData, setObjUserEventConnectionData] = useState([]);
    const [objWorkflows, setObjWorkflows] = useState([]);
    const [formattedWorkflowsArr, setFormattedWorkflowsArr] = useState([]);
    const [objSelectedNewClientGoHighLevelWorkFlow, setObjSelectedNewClientGoHighLevelWorkFlow] = useState({});
    const [objSelectedQuoteSentGoHighLevelWorkFlow, setObjSelectedQuoteSentGoHighLevelWorkFlow] = useState({});
    const [objSelectedQuoteApprovedGoHighLevelWorkFlow, setObjSelectedQuoteApprovedGoHighLevelWorkFlow] = useState({});
    const [objSelectedJobScheduledGoHighLevelWorkFlow, setObjSelectedJobScheduledGoHighLevelWorkFlow] = useState({});
    const [objSelectedJobCompletedGoHighLevelWorkFlow, setObjSelectedJobCompletedGoHighLevelWorkFlow] = useState({});
    const [objSelectedInvoiceSentGoHighLevelWorkFlow, setObjSelectedInvoiceSentGoHighLevelWorkFlow] = useState({});
    const [objSelectedInvoicePaidGoHighLevelWorkFlow, setObjSelectedInvoicePaidGoHighLevelWorkFlow] = useState({});
    const [objFormErrors, setObjFormErrors] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loadWorkFlowsData = () => {
        setIsLoadingWorkflows(true);
        setIsWorkflowsLoaded(false);
        dispatch(getGoHighLevelWokflows())
            .then(unwrapResult)
            .then((originalPromiseResult) => {
                setIsLoadingWorkflows(false);
                setIsWorkflowsLoaded(true);
                console.log("originalPromiseResult in getGoHighLevelWokflows");
                console.log(originalPromiseResult);
                const response = originalPromiseResult;
                console.log("response.data in getGoHighLevelWokflows");
                console.log(response.data);
                if(response.data.error === false){
                    
                    setObjWorkflows(response.data.objWorkFlows);
                    setFormattedWorkflowsArr(response.data.formattedWorkflowsArr);
                }else{
                    // setIsWorkflowsLoaded(false);
                }
                
            })
            .catch((rejectedValueOrSerializedError) => {
                setIsLoadingWorkflows(false);
                setIsWorkflowsLoaded(true);
                showMsg('error', "Ops something went wrong please try again")
                console.log("rejectedValueOrSerializedError");
                console.log(rejectedValueOrSerializedError);
            });
    }

    const loadJobberGoHighLevelEventConnections = () => {
        setIsLoadingEeventConnections(true);
        dispatch(fetchJobberGoHighLevelEventConnection())
            .then(unwrapResult)
            .then((originalPromiseResult) => {
                setIsLoadingEeventConnections(false);
                console.log("originalPromiseResult in fetchJobberGoHighLevelEventConnection");
                console.log(originalPromiseResult);
                const response = originalPromiseResult;
                console.log("response.data in fetchJobberGoHighLevelEventConnection");
                console.log(response.data);
                setObjUserEventConnectionData(response.data.objEventConnection);
                const objEventConnection = response.data.objEventConnection;
                if(objEventConnection && objEventConnection !== null){
                    setObjSelectedNewClientGoHighLevelWorkFlow(objEventConnection.objSelectedNewClientGoHighLevelWorkFlow)
                    setObjSelectedQuoteSentGoHighLevelWorkFlow(objEventConnection.objSelectedQuoteSentGoHighLevelWorkFlow)
                    setObjSelectedQuoteApprovedGoHighLevelWorkFlow(objEventConnection.objSelectedQuoteApprovedGoHighLevelWorkFlow)
                    setObjSelectedJobScheduledGoHighLevelWorkFlow(objEventConnection.objSelectedJobScheduledGoHighLevelWorkFlow)
                    setObjSelectedJobCompletedGoHighLevelWorkFlow(objEventConnection.objSelectedJobCompletedGoHighLevelWorkFlow)
                    setObjSelectedInvoiceSentGoHighLevelWorkFlow(objEventConnection.objSelectedInvoiceSentGoHighLevelWorkFlow)
                    setObjSelectedInvoicePaidGoHighLevelWorkFlow(objEventConnection.objSelectedInvoicePaidGoHighLevelWorkFlow)
                }
            })
            .catch((rejectedValueOrSerializedError) => {
                setIsLoadingEeventConnections(false);
                showMsg('error', "Ops something went wrong please try again")
                console.log("rejectedValueOrSerializedError");
                console.log(rejectedValueOrSerializedError);
            });
    }
    useEffect(() => {
        loadWorkFlowsData();
        loadJobberGoHighLevelEventConnections();
        return () => {
        };
    }, []);

    const validateSaveEventConnections = () => {
		let error = false;
		const _objFormErrors = {};
		if (Object.keys(objSelectedNewClientGoHighLevelWorkFlow).length === 0) {
			error = true;
			_objFormErrors['objSelectedNewClientGoHighLevelWorkFlow'] = 'Please select your event of new client in Jobber.';
		}
		if (Object.keys(objSelectedQuoteSentGoHighLevelWorkFlow).length === 0) {
			error = true;
			_objFormErrors['objSelectedQuoteSentGoHighLevelWorkFlow'] = 'Please select your event of quote sent on jobber.';
		}
		if (Object.keys(objSelectedQuoteApprovedGoHighLevelWorkFlow).length === 0) {
			error = true;
			_objFormErrors['objSelectedQuoteApprovedGoHighLevelWorkFlow'] = 'Please select your event of quote approved on jobber.';
		}
		if (Object.keys(objSelectedJobScheduledGoHighLevelWorkFlow).length === 0) {
			error = true;
			_objFormErrors['objSelectedJobScheduledGoHighLevelWorkFlow'] = 'Please select your event of job scheduled on jobber.';
		}
		if (Object.keys(objSelectedJobCompletedGoHighLevelWorkFlow).length === 0) {
			error = true;
			_objFormErrors['objSelectedJobCompletedGoHighLevelWorkFlow'] = 'Please select your event of job completed on jobber.';
		}
        if (Object.keys(objSelectedInvoiceSentGoHighLevelWorkFlow).length === 0) {
			error = true;
			_objFormErrors['objSelectedInvoiceSentGoHighLevelWorkFlow'] = 'Please select your event of invoice sent on jobber.';
		}
        if (Object.keys(objSelectedInvoicePaidGoHighLevelWorkFlow).length === 0) {
			error = true;
			_objFormErrors['objSelectedInvoicePaidGoHighLevelWorkFlow'] = 'Please select your event of invoice paid on jobber.';
		}
		setObjFormErrors(_objFormErrors);
		return error;
	}
	const btnOnClickSaveEventConnections = () => {
		const isError = validateSaveEventConnections();
		if (isError === false) {
            const objPostData = {
                objSelectedNewClientGoHighLevelWorkFlow,
                objSelectedQuoteSentGoHighLevelWorkFlow,
                objSelectedQuoteApprovedGoHighLevelWorkFlow,
                objSelectedJobScheduledGoHighLevelWorkFlow,
                objSelectedJobCompletedGoHighLevelWorkFlow,
                objSelectedInvoiceSentGoHighLevelWorkFlow,
                objSelectedInvoicePaidGoHighLevelWorkFlow,
			};
			setIsSavingEventConnections(true);
			dispatch(saveJobberGoHighLevelEventConnections(objPostData))
				.then(unwrapResult)
				.then((originalPromiseResult) => {
					setIsSavingEventConnections(false);
					console.log("originalPromiseResult in saveJobberGoHighLevelEventConnections");
					console.log(originalPromiseResult);
					const response = originalPromiseResult;
					console.log("response.data in saveJobberGoHighLevelEventConnections");
					console.log(response.data);
					if (response.data.error === false) {
                        showMsg('success',response.data.msg);
					} else {
                        showMsg('error',response.data.msg);
					}
				})
				.catch((rejectedValueOrSerializedError) => {
					setIsSavingEventConnections(false);
					showMsg('error',"Ops something went wrong please try again")
					console.log("rejectedValueOrSerializedError");
					console.log(rejectedValueOrSerializedError);
				});
		} else {
            showMsg('error', "Please correct the errors");
		}

	}
    console.log("isWorkflowsLoaded");
    console.log("isWorkflowsLoaded");
    console.log("isWorkflowsLoaded");
	console.log(isWorkflowsLoaded);
    return (
        <>
            {
                isLoadingWorkflows === false && isWorkflowsLoaded  ?
                <SelectWorkFlowsForJobberIntegrationForm 
                    isLoadingWorkflows={isLoadingWorkflows}
                    objWorkflows={objWorkflows}
                    formattedWorkflowsArr={formattedWorkflowsArr}
                    objFormErrors={objFormErrors}
                    setObjFormErrors={setObjFormErrors}
                    objSelectedNewClientGoHighLevelWorkFlow={objSelectedNewClientGoHighLevelWorkFlow}
                    setObjSelectedNewClientGoHighLevelWorkFlow={setObjSelectedNewClientGoHighLevelWorkFlow}
                    objSelectedQuoteSentGoHighLevelWorkFlow={objSelectedQuoteSentGoHighLevelWorkFlow}
                    setObjSelectedQuoteSentGoHighLevelWorkFlow={setObjSelectedQuoteSentGoHighLevelWorkFlow}
                    objSelectedQuoteApprovedGoHighLevelWorkFlow={objSelectedQuoteApprovedGoHighLevelWorkFlow}
                    setObjSelectedQuoteApprovedGoHighLevelWorkFlow={setObjSelectedQuoteApprovedGoHighLevelWorkFlow}
                    objSelectedJobScheduledGoHighLevelWorkFlow={objSelectedJobScheduledGoHighLevelWorkFlow}
                    setObjSelectedJobScheduledGoHighLevelWorkFlow={setObjSelectedJobScheduledGoHighLevelWorkFlow}
                    objSelectedJobCompletedGoHighLevelWorkFlow={objSelectedJobCompletedGoHighLevelWorkFlow}
                    setObjSelectedJobCompletedGoHighLevelWorkFlow={setObjSelectedJobCompletedGoHighLevelWorkFlow}
                    objSelectedInvoiceSentGoHighLevelWorkFlow={objSelectedInvoiceSentGoHighLevelWorkFlow}
                    setObjSelectedInvoiceSentGoHighLevelWorkFlow={setObjSelectedInvoiceSentGoHighLevelWorkFlow}
                    objSelectedInvoicePaidGoHighLevelWorkFlow={objSelectedInvoicePaidGoHighLevelWorkFlow}
                    setObjSelectedInvoicePaidGoHighLevelWorkFlow={setObjSelectedInvoicePaidGoHighLevelWorkFlow}
                    btnOnClickSaveEventConnections={btnOnClickSaveEventConnections}
                    isSavingEventConnections={isSavingEventConnections}
                    setIsSavingEventConnections={setIsSavingEventConnections}
                />
                :
                <LoadingSpinner subClass="text-center" color="danger" size="lg" />
            }
                    
        </>
    );
};

export default WorkFlowsSelectionForm;
