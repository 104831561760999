import { combineReducers } from "redux";
// import authReducers from "./auth/";
import goHighLevelOAuthSlice from "./goHighLevelOAuthSlice";
import goHighLevelWorkFlowsSlice from "./goHighLevelWorkFlowsSlice";

const goHighLevelReducers = combineReducers({
  goHighLevelOAuth: goHighLevelOAuthSlice,
  goHighLevelWorkFlows: goHighLevelWorkFlowsSlice,
});

export default goHighLevelReducers;