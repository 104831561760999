import { createSlice } from "@reduxjs/toolkit";
import { getGoHighLevelWokflows } from "./goHighLevelWorkFlowsActions";


const initialState = {
  
};

const goHighLevelWorkFlowsSlice = createSlice({
  name: "goHighLevelWorkFlows",
  initialState,
  reducers: {
  },
  extraReducers: {
    // get Workflows
    [getGoHighLevelWokflows.pending]: (state) => {
      state.loading = true;
      state.objgetGoHighLevelWokflowsData = null;
    },
    [getGoHighLevelWokflows.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objgetGoHighLevelWokflowsData = payload;
    },
    [getGoHighLevelWokflows.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objgetGoHighLevelWokflowsData = payload;
    },
    
  },
});

export default goHighLevelWorkFlowsSlice.reducer;
