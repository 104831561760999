import React, { useEffect, useRef, useState } from 'react';
import { auth } from "../../features/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingSpinner from '../../components/utils/Helpers/Loading/LoadingSpinner';

const RedirectToDashboardIfLoggedInCheck = ({Component, checkAuth }) => {
    const dispatch = useDispatch();
    const _isMounted = useRef(true); // Initial value _isMounted = true
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // useEffect(() => {
    //     loadAuthData();
    //     return () => {
    //       _isMounted.current = false;
    //     };
    // }, [page_name]);
    useEffect(() => {
      if (_isMounted.current) { // Check always mounted component
        // continue treatment of AJAX response... ;
        loadAuthData();
      }
      return () => {
        _isMounted.current = false;
      };
    });
    
    const { objUser } = useSelector((state) => ({
      objUser: state.auth.user.objUser,
    }));

    const loadAuthData = async () => {
        setLoading(true);
        const objUserAuthResp = await dispatch(auth());
        console.log('objUserAuthResp in RedirectToDashboardIfLoggedInCheck')
        console.log('objUserAuthResp in RedirectToDashboardIfLoggedInCheck')
        console.log(objUserAuthResp)
        const objUserAuth = objUserAuthResp.payload;
        if (checkAuth && !objUserAuth.isAuth) {
          // if (isRedirect) {
          //     navigate('/login');
          //     return false;
          // }
        } else if (checkAuth && objUserAuth.isAuth) {
          navigate('/dashboard');
          return false;
        }
        setLoading(false);
    }



    console.log('objUser in RedirectToDashboardIfLoggedInCheck')
    console.log('objUser in RedirectToDashboardIfLoggedInCheck')
    console.log(objUser)

    if (loading) {
        return (
            <LoadingSpinner subClass="text-center" color="danger" />
        )
    }
    console.log('loading after in RedirectToDashboardIfLoggedInCheck')
    console.log('loading after in RedirectToDashboardIfLoggedInCheck')
    console.log(loading)
    console.log('objUser after loading RedirectToDashboardIfLoggedInCheck')
    console.log('objUser after loading RedirectToDashboardIfLoggedInCheck')
    console.log(objUser)
    
    // if (loading === false && checkAuth && Object.keys(objUser).length === 0) {
    // // if (loading === false && checkAuth && Object.keys(objUser).length === 0) {
    //     return (
    //         <LoadingSpinner subClass="text-center" color="danger" />
    //     )
    // }
    if (loading === false && checkAuth && typeof objUser === 'object' && Object.keys(objUser).length > 0) {
        // console.log('user in authhoc')
        // console.log('user in authhoc')
        // console.log(user)
        // console.log('Object.keys(user).length in authhoc')
        // console.log('Object.keys(user).length in authhoc')
        // console.log(Object.keys(user).length)
        
        let { objUserAuth } = objUser;
        // alert(Object.keys(objUserAuth).length)
        // alert(objUserAuth.email)
        return (
            <Component
                objUserAuth={objUserAuth}
            />
        )
    } else {
        return (
          <LoadingSpinner
            subClass="text-center" color="danger"
          />
        )
    }
};

export default RedirectToDashboardIfLoggedInCheck



