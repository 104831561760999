import { createSlice } from "@reduxjs/toolkit";
import { saveJobberGoHighLevelEventConnections, fetchJobberGoHighLevelEventConnection } from "./goHighLevelAndJobberEventConnectionsActions";


const initialState = {

};

const goHighLevelAndJobberEventConnectionsSlice = createSlice({
    name: "goHighLevelAndJobberEventConnections",
    initialState,
    reducers: {
    },
    extraReducers: {
        
        // saveJobberGoHighLevelEventConnections
        [saveJobberGoHighLevelEventConnections.pending]: (state) => {
            state.loading = true;
            state.objSaveJobberGoHighLevelEventConnectionsData = null;
        },
        [saveJobberGoHighLevelEventConnections.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.objSaveJobberGoHighLevelEventConnectionsData = payload;
        },
        [saveJobberGoHighLevelEventConnections.rejected]: (state, { payload }) => {
            state.loading = false;
            state.objSaveJobberGoHighLevelEventConnectionsData = payload;
        },

        // fetchJobberGoHighLevelEventConnection
        [fetchJobberGoHighLevelEventConnection.pending]: (state) => {
            state.loading = true;
            state.objFetchJobberGoHighLevelEventConnectionData = null;
        },
        [fetchJobberGoHighLevelEventConnection.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.objFetchJobberGoHighLevelEventConnectionData = payload;
        },
        [fetchJobberGoHighLevelEventConnection.rejected]: (state, { payload }) => {
            state.loading = false;
            state.objFetchJobberGoHighLevelEventConnectionData = payload;
        },


    },
});

export default goHighLevelAndJobberEventConnectionsSlice.reducer;
