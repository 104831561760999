import React from "react"
// import { Spinner } from "reactstrap"
const LoadingSpinner = (props) => {

    return (
        <div className={`${props.subClass}`}>
          <div className={`spinner-border text-${props.color}`} role="status">
              <span className="visually-hidden">Loading...</span>
          </div>
        </div>
    )
}

export default LoadingSpinner;
